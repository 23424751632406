<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>商城管理</el-breadcrumb-item>
            <el-breadcrumb-item>商城首页</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="order-stat">
         <el-row class="statistical-data">
            <el-col :span="8">
               <el-row><h6>订单数</h6></el-row>
               <el-row>
                  <el-col :span="6">今日</el-col>
                  <el-col :span="12">{{data.todayOrderCount}}个</el-col>
               </el-row>
               <el-row>
                  <el-col :span="6">昨日</el-col>
                  <el-col :span="12">{{data.yesterdayOrderCount}}个</el-col>
               </el-row>
               <el-row>
                  <el-col :span="6">历史巅峰</el-col>
                  <el-col :span="12">个</el-col>
               </el-row>
            </el-col>
            <el-col :span="8">
               <el-row><h6>成交金额</h6></el-row>
               <el-row>
                  <el-col :span="6">今日</el-col>
                  <el-col :span="12">{{data.todaySumMoney}}元</el-col>
               </el-row>
               <el-row>
                  <el-col :span="6">昨日</el-col>
                  <el-col :span="12">{{data.yesterdaySumMoney}}元</el-col>
               </el-row>
               <el-row>
                  <el-col :span="6">历史巅峰</el-col>
                  <el-col :span="12">元</el-col>
               </el-row>
            </el-col>
            <el-col :span="8">
               <el-row>
                  <el-col :span="8">
                     <h6>累计成交金额</h6>
                  </el-col>
                  <el-col :span="12">{{data.totalMoney}}元</el-col>
               </el-row>
               <el-row>
                  <el-col :span="8">累计订单数</el-col>
                  <el-col :span="12">{{data.totalOrderCount}}个</el-col>
               </el-row>
               <el-row>
                  <el-col :span="8">累计访问量</el-col>
                  <el-col :span="12">次/人</el-col>
               </el-row>
               <el-row>
                  <el-col :span="8">累计商家数</el-col>
                  <el-col :span="12">个</el-col>
               </el-row>
            </el-col>
         </el-row>
         <el-row class="bg">
            <el-row class="order-title">
               <h6>订单统计</h6>
            </el-row>
            <el-row class="order-num">
               <el-col :span="3">
                  <p>全年订单量</p>
                  <span>{{hotelOrderCount.year}}</span>
                  <span>{{GoodsOrderCount.year}}</span>
               </el-col>
               <el-col :span="3">
                  <p>本月订单量</p>
                  <span>{{hotelOrderCount.month}}</span>
                  <span>{{GoodsOrderCount.month}}</span>
               </el-col>
               <el-col :span="3">
                  <p>本周订单量</p>
                  <span>{{hotelOrderCount.week}}</span>
                  <span>{{GoodsOrderCount.week}}</span>
               </el-col>
               <el-col :span="3">
                  <p>今日订单量</p>
                  <span>{{hotelOrderCount.day}}</span>
                  <span>{{GoodsOrderCount.day}}</span>
               </el-col>
            </el-row>
            <el-row class="order-date">
               <!-- <el-col class="order-type">
                  <div class="">
                     <p></p>
                  </div>
                  <span>酒店订单</span>
                  <div class="">
                     <p></p>
                  </div>
                  <span>商城订单</span>
               </el-col> -->
               <el-col class="m-right-10">
                  <el-button-group>
                     <el-button  @click="handleOrderDate('DAY')" :class="orderDateType === 'DAY' ? 'bg-gradient' : ''">今日</el-button>
                     <el-button  @click="handleOrderDate('WEEK')" :class="orderDateType === 'WEEK' ? 'bg-gradient' : ''">本周</el-button>
                     <el-button  @click="handleOrderDate('MONTH')" :class="orderDateType === 'MONTH' ? 'bg-gradient' : ''">本月</el-button>
                     <el-button  @click="handleOrderDate('YEAR')" :class="orderDateType === 'YEAR' ? 'bg-gradient' : ''">全年</el-button>
                  </el-button-group>
               </el-col>
               <el-col>
                  <date-packer :datePacker="orderDateVal" @setDatePacker="getOrderDatePacker"></date-packer>
               </el-col>
            </el-row>
            <el-row class="order-line-chart">
               <schart class="wrapper-line" canvasId="canvas-line" v-if="showLabel" :options="lineOptions" />
            </el-row>
         </el-row>
      </el-row>
   </div>
</template>

<script>
   import Schart from 'vue-schart'
   import { urlObj } from '@/api/interface'
   import { mapState } from "vuex";
   export default {
      data() {
         return{
            data: {},   // 订单统计数据
            lineOptions: {
               type: 'line',
               labels: [0],
               datasets: [{
                  label: '酒店订单',
                  data: [0]
               }, {
                  label: '商城订单',
                  data: [0]
               }]
            },
            hotelOrderCount: {    // 酒店 订单统计总数
               year: 0,
               month: '一',
               week: '一',
               day: '一'
            },
            GoodsOrderCount: {    // 商城 订单统计总数
               year: 0,
               month: '一',
               week: '一',
               day: '一'
            },
           hotelId:'',
           orderDateVal: [],     // 订单统计时间段
            orderBeginTime: '',   // 订单统计开始时间
            orderEndTime: '',     // 订单统计结束时间
            orderDateType: 'YEAR',// 订单统计时间类型
            showLabel:true

         }
      },
     computed: {
       ...mapState(['hotelInfo'])
     },
     watch: {
       hotelInfo: {
         handler(newVal, oldVal) {
           if(newVal.id !== oldVal.id) {
             this.hotelId = this.hotelInfo.id
             this.getStatistics()
             this.getOrderStatistics()
           }
         },
       }
     },
      components: {
         Schart,
      },
      mounted() {
        this.hotelId = this.hotelInfo.id
         this.getStatistics()
         this.getOrderStatistics()
      },
      methods: {
         // 获取数据统计
         getStatistics(){
            const url = urlObj.shopStatistics
            this.$axios.get(url, {hotelId:this.hotelInfo.id}).then(res => {
               if (res.success){
                  this.data = res.records
               }
            })
         },
         // 获取订单统计
         getOrderStatistics(){
            const url = urlObj.orderStatistics
            let param = {
               orderType: 'HOTEL',
               dateType: this.orderDateType,
               beginTime: this.orderBeginTime.split(" ")[0] ,
               endTime: this.orderEndTime.split(" ")[0],
              hotelId:this.hotelInfo.id
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  let data = res.records
                  let arr = []
                  this.lineOptions.datasets[0].data = []
                  this.lineOptions.labels = []
                  data.forEach(item => {
                     arr.push(item.date)
                     this.lineOptions.datasets[0].data.push(Number(item.orderCount))
                  })
                  this.hotelOrderCount[this.orderDateType.toLowerCase()] = this.lineOptions.datasets[0].data.reduce((num1, num2) => {
                     return num1 + num2
                  })
                  switch (this.orderDateType) {
                     case "YEAR" :
                        return this.lineOptions.labels = arr
                     case 'MONTH' :
                        return this.lineOptions.labels = arr.map(item => {
                           return item.split('-')[2]
                        }).sort()
                     case 'WEEK' :
                        return this.lineOptions.labels = arr
                     case 'DAY' :
                        return this.lineOptions.labels = arr.map(item => {
                           return item.split(' ')[1]
                        }).sort()
                     case '' :
                        return this.lineOptions.labels = arr
                  }
               }
            }).catch(error => {
               console.log(error)
            })

            param.orderType = 'GOODS'
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  let data = res.records
                  if (data.length != 0){
                     this.lineOptions.datasets[1].data = []
                     data.forEach(item => {
                        this.lineOptions.datasets[1].data.push(Number(item.orderCount))
                     })
                     this.GoodsOrderCount[this.orderDateType.toLowerCase()] = this.lineOptions.datasets[1].data.reduce((num1, num2) => {
                        return num1 + num2
                     })
                  }

               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 按订单统计时间段过滤
         getOrderDatePacker(val){
            this.orderDateVal = val
            if (this.orderDateVal) {
               this.orderBeginTime = this.orderDateVal[0]
               this.orderEndTime = this.orderDateVal[1]
            } else {
               this.orderBeginTime = ''
               this.orderEndTime = ''
            }
            this.orderDateType = ''
            this.getOrderStatistics()
         },
         // 按订单统计时间类型过滤
         handleOrderDate(date){
            this.orderDateType = date
            this.orderBeginTime = ''
            this.orderEndTime = ''
            this.orderDateVal = []
            this.getOrderStatistics()
         },
      }
   }
</script>

<style scoped>
   .statistical-data{
      font-size: 13px;
      line-height: 34px;
      text-indent: 10px;
      padding: 30px 20px;
   }
   .statistical-data h6{
      font-size: 14px;
   }
   .statistical-data > .el-col{
      padding: 0 10px;
      position: relative;
   }
   .statistical-data > .el-col:before{
      content: '';
      position: absolute;
      width: 1px;
      height: 100px;
      top: 20px;
      right: 10%;
      background-color: #eeeeee;
   }

   .cont .order-stat{
      padding: 0 5px;
      background:#FFF;
      border-radius: 8px;
   }
   .cont .order-stat .order-title{
      border-bottom: solid 1px #eeeeee;
   }
   .cont .order-stat .order-title h6{
      line-height: 40px;
      font-size: 1rem;
      padding: 10px 30px;
   }
   .cont .order-stat .order-num{
      padding: 20px 30px 0;
      font-size: 14px;
      line-height: 26px;
      text-align: left;
   }
   .cont .order-stat .order-num span{
      display: block;
      font-weight: bold;
      position: relative;
      padding: 0 0 0 16px;
   }
   .cont .order-stat .order-num span:before{
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #f5a623;
      left: 0;
      top: 10px;
      position: absolute;
   }
   .cont .order-stat .order-num span:nth-child(2):before{
      background: #4a90e2;
   }
   .cont .order-stat .order-date{
      display: flex;
      justify-content: flex-end;
      line-height: 30px;
      padding: 10px 30px;
   }
   .cont .order-stat .order-date .order-type{
      display:flex;
      align-items: center;
      flex:1;
   }
   .cont .order-stat .order-date .order-type > div{
      width:60px;
      height:2px;
      background:#4a90e2;
      margin:0 0 0 60px;
   }
   .cont .order-stat .order-date .order-type > div > p {
      height:8px;
      width:8px;
      border-radius:50%;
      background:#4a90e2;
      margin:-3px auto 0;
   }
   .cont .order-stat .order-date div{
      width: auto;
   }
   .cont .order-stat .order-date div:nth-child(1){
      font-size: 0.9rem;
   }
   .cont .order-stat .order-date span{
      margin: 0 14px 0 0;
   }
   .cont .order-stat .order-line-chart{
      padding: 10px 30px;
   }
   .cont .order-stat .order-line-chart .wrapper-line{
      height: 300px;
   }
</style>
